import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ProgramsSectionBlog3 from "../components/blog/ProgramsSectionBlog3";
import ProgramsSectionBlog2 from "../components/blog/ProgramsSectionBlog2";
import ProgramsSectionTestimonials4 from "../components/testimonials/ProgramsSectionTestimonials4";
import ProgramsSectionCareer5 from "../components/career/ProgramsSectionCareer5";
import ProgramsSectionCareer6 from "../components/career/ProgramsSectionCareer6";
import ProgramsSectionNewsletter1 from "../components/newsletter/ProgramsSectionNewsletter1";
import Nav from "../components/Nav/Nav";
import TestSectionContact1 from "../components/contact/TestSectionContact1";
import IndexSectionFooters7 from "../components/footers/IndexSectionFooters7";
import { useSearchParams } from 'react-router-dom';

const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Programs() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <Nav></Nav>
     
    <TestSectionContact1></TestSectionContact1>
    
      <IndexSectionFooters7></IndexSectionFooters7>
    </React.Fragment>
  );
}
