import React from "react";
import Img1 from "../../assets/images/img1.png";
import Img2 from "../../assets/images/img2.png";
import Img3 from "../../assets/images/img3.png";
import Img4 from "../../assets/images/img4.png";
import "./Landing.css";
import { Programs } from "../../data/Programs";
import { Link } from "react-router-dom";
export default function IndexSectionHeaders2() {
  return (
    <React.Fragment>
      <>
        <section className="relative ">
          <img
            className="absolute top-0 left-0"
            src="flaro-assets/images/headers/gradient4.svg"
            alt
          />

          <div
            className="overflow-hidden  pb-48 pt-48 pt-16 "
            style={{ paddingTop: 200 }}
          >
            <div className="relative container px-4 mx-auto">
              <div className="flex flex-wrap -m-8">
                <div className="w-full md:w-1/2 lg:w-5/12 xl:w-6/12 p-8">
                  <h1 className="mb-9 text-6xl md:text-8xl lg:text-13xl font-bold font-heading md:max-w-2xl leading-none">
                    {/* احصل على أفضل خطط الإرشاد{" "} */}
                    وفر لطلابك أفضل برامج الإرشاد 
                  </h1>
                  <div>
                    <p className="mb-9 text-xl text-gray-900 font-medium md:max-w-sm">
                      لتعزيز مهاراتهم الشخصية والمهنية، وتمكينهم من تحقيق التميز
                      والنجاح في المستقبل.
                    </p>
                    <div className="mb-12 md:inline-block">
                      <Link to="/programs">
                        <button
                          className="py-4 px-6 w-full text-white font-semibold border border-indigo-700 rounded-xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200"
                          type="button"
                        >
                          البرامج التكنلوجية
                        </button>
                      </Link>

                      <Link to="/programs">
                        <button
                          className=" mt-4 py-4 px-4 text-base w-full font-medium border border-gray-400 hover:border-gray-500 rounded-xl focus:ring focus:ring-gray-50 bg-white hover:bg-gray-50 transition ease-in-out duration-200"
                          type="button"
                        >
                          البرامج التربوية والاجتماعية{" "}
                        </button>
                      </Link>
                    </div>
                    <h3 className="mb-3 text-gray-900 font-semibold">
                      موثوق به من قبل أكثر من 100 عميل
                    </h3>
                    <div className="flex flex-wrap items-center -m-1">
                      <div className="w-auto p-1">
                        <div className="flex flex-wrap -m-0.5">
                          <div className="w-auto p-0.5">
                            <svg
                              width={17}
                              height={16}
                              viewBox="0 0 17 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.707 1.21267C8.02812 0.224357 9.42632 0.224357 9.74744 1.21267L10.8948 4.74387C11.0384 5.18586 11.4503 5.48511 11.915 5.48511H15.6279C16.6671 5.48511 17.0992 6.81488 16.2585 7.42569L13.2547 9.60809C12.8787 9.88126 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.327 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.327 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88126 4.19978 9.60809L1.19596 7.42569C0.355248 6.81488 0.787317 5.48511 1.82649 5.48511H5.53942C6.00415 5.48511 6.41603 5.18586 6.55964 4.74387L7.707 1.21267Z"
                                fill="#186972"
                              />
                            </svg>
                          </div>
                          <div className="w-auto p-0.5">
                            <svg
                              width={17}
                              height={16}
                              viewBox="0 0 17 16"
                              fill="#186972"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.707 1.21267C8.02812 0.224357 9.42632 0.224357 9.74744 1.21267L10.8948 4.74387C11.0384 5.18586 11.4503 5.48511 11.915 5.48511H15.6279C16.6671 5.48511 17.0992 6.81488 16.2585 7.42569L13.2547 9.60809C12.8787 9.88126 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.327 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.327 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88126 4.19978 9.60809L1.19596 7.42569C0.355248 6.81488 0.787317 5.48511 1.82649 5.48511H5.53942C6.00415 5.48511 6.41603 5.18586 6.55964 4.74387L7.707 1.21267Z"
                                fill="#186972"
                              />
                            </svg>
                          </div>
                          <div className="w-auto p-0.5">
                            <svg
                              width={17}
                              height={16}
                              viewBox="0 0 17 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.707 1.21267C8.02812 0.224357 9.42632 0.224357 9.74744 1.21267L10.8948 4.74387C11.0384 5.18586 11.4503 5.48511 11.915 5.48511H15.6279C16.6671 5.48511 17.0992 6.81488 16.2585 7.42569L13.2547 9.60809C12.8787 9.88126 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.327 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.327 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88126 4.19978 9.60809L1.19596 7.42569C0.355248 6.81488 0.787317 5.48511 1.82649 5.48511H5.53942C6.00415 5.48511 6.41603 5.18586 6.55964 4.74387L7.707 1.21267Z"
                                fill="#186972"
                              />
                            </svg>
                          </div>
                          <div className="w-auto p-0.5">
                            <svg
                              width={17}
                              height={16}
                              viewBox="0 0 17 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.707 1.21267C8.02812 0.224357 9.42632 0.224357 9.74744 1.21267L10.8948 4.74387C11.0384 5.18586 11.4503 5.48511 11.915 5.48511H15.6279C16.6671 5.48511 17.0992 6.81488 16.2585 7.42569L13.2547 9.60809C12.8787 9.88126 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.327 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.327 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88126 4.19978 9.60809L1.19596 7.42569C0.355248 6.81488 0.787317 5.48511 1.82649 5.48511H5.53942C6.00415 5.48511 6.41603 5.18586 6.55964 4.74387L7.707 1.21267Z"
                                fill="#186972"
                              />
                            </svg>
                          </div>
                          <div className="w-auto p-0.5">
                            <svg
                              width={17}
                              height={16}
                              viewBox="0 0 17 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.707 1.21267C8.02812 0.224357 9.42632 0.224357 9.74744 1.21267L10.8948 4.74387C11.0384 5.18586 11.4503 5.48511 11.915 5.48511H15.6279C16.6671 5.48511 17.0992 6.81488 16.2585 7.42569L13.2547 9.60809C12.8787 9.88126 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.327 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.327 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88126 4.19978 9.60809L1.19596 7.42569C0.355248 6.81488 0.787317 5.48511 1.82649 5.48511H5.53942C6.00415 5.48511 6.41603 5.18586 6.55964 4.74387L7.707 1.21267Z"
                                fill="#186972"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="w-auto p-1">
                        <div className="flex flex-wrap -m-0.5">
                          <div className="w-auto p-0.5">
                            <p className="text-gray-900 font-bold">4.7/5</p>
                          </div>
                          <div className="w-auto p-0.5">
                            <p className="text-gray-600 font-medium">
                              (45 التقييمات)
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="w-full md:w-1/2 lg:w-7/12 xl:w-8/12 xl:absolute xl:left-0 xl:-bottom-20 p-8 left-0 md:hidden lg:block xl:block sm:hidden  hidden  "
                  style={{ top: 0 }}
                >
                  <div className="flex flex-wrap justify-center items-center lg:justify-end -m-3">
                    <div
                      className="w-auto lg:w-1/3 xl:pt-28 p-3  "
                      style={{ paddingTop: 170 }}
                    >
                      <div className="flex flex-wrap justify-end">
                        <div className="w-auto">
                          {/* 9 */}
                          <Link
                            to={`/program/${Programs[9].id}`} /* relative="path" */
                            params={{ id: Programs[9].id }}
                          >
                            <img
                              className="mx-auto transform hover:-translate-y-16 transition ease-in-out duration-1000 rounded-3xl  "
                              src={Img2}
                              alt
                            />
                          </Link>
                          {/*  <a href="#">
                            <img
                              className="mx-auto transform hover:-translate-y-16 transition ease-in-out duration-1000 rounded-3xl  "
                              src={Img2}
                              alt
                            />
                          </a> */}
                        </div>
                      </div>
                    </div>
                    <div className="w-auto lg:w-1/3 p-3">
                      <div className="flex flex-wrap justify-center -m-3">
                        <div className="w-auto p-3">
                          {/* 2 */}
                          <Link
                            to={`/program/${Programs[2].id}`} /* relative="path" */
                            params={{ id: Programs[2].id }}
                          >
                            <img
                              className="mx-auto transform hover:-translate-y-16 transition ease-in-out duration-1000 rounded-3xl"
                              src={Img1}
                              alt
                            />
                          </Link>
                          {/*  <a href="#">
                            <img
                              className="mx-auto transform hover:-translate-y-16 transition ease-in-out duration-1000 rounded-3xl"
                              src={Img1}
                              alt
                            />
                          </a> */}
                        </div>
                        <div className="w-auto p-3 pt-8 ">
                          {/* 3 */}
                          <Link
                            to={`/program/${Programs[3].id}`} /* relative="path" */
                            params={{ id: Programs[3].id }}
                          >
                            <img
                              className="mx-auto transform hover:-translate-y-16 transition ease-in-out duration-1000 rounded-3xl"
                              src={Img3}
                              alt
                            />
                          </Link>
                          {/*  <a href="#">
                            <img
                              className="mx-auto transform hover:-translate-y-16 transition ease-in-out duration-1000 rounded-3xl"
                              src={Img3}
                              alt
                            />
                          </a> */}
                        </div>
                      </div>
                    </div>
                    <div className="w-auto lg:w-1/3 p-3 ">
                      <div className="flex flex-wrap">
                        <div className="w-auto">
                          {/* 0 */}
                          <Link
                            to={`/program/${Programs[0].id}`} /* relative="path" */
                            params={{ id: Programs[0].id }}
                          >
                            <img
                              className="mx-auto transform hover:-translate-y-16 transition ease-in-out duration-1000 rounded-3xl"
                              src={Img4}
                              alt
                            />
                          </Link>
                          {/* <a href="#">
                            <img
                              className="mx-auto transform hover:-translate-y-16 transition ease-in-out duration-1000 rounded-3xl"
                              src={Img4}
                              alt
                            />
                          </a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="w-full md:w-1/2 lg:w-7/12 xl:w-8/12 xl:absolute xl:left-0 xl:-bottom-20 p-8 left-0 md:block xl:hidden lg:hidden sm:block  "
                  style={{ top: 0 }}
                >
                  <div className="w-auto lg:w-1/3 p-3 ">
                    <div className="flex flex-wrap">
                      <div className="w-auto">
                        {/* 0 */}
                        <Link
                          to={`/program/${Programs[0].id}`} /* relative="path" */
                          params={{ id: Programs[0].id }}
                        >
                          <img
                            className="mx-auto transform hover:-translate-y-16 transition ease-in-out duration-1000 rounded-3xl"
                            src={Img4}
                            alt
                          />
                        </Link>
                        {/* <a href="#">
                            <img
                              className="mx-auto transform hover:-translate-y-16 transition ease-in-out duration-1000 rounded-3xl"
                              src={Img4}
                              alt
                            />
                          </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
