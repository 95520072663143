import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ErrorSectionHttpCodes1 from '../components/http-codes/ErrorSectionHttpCodes1';
import ErrorSectionNavigations2 from '../components/navigations/ErrorSectionNavigations2';
import ErrorSectionNavigations3 from '../components/navigations/ErrorSectionNavigations3';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Error() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <ErrorSectionHttpCodes1 />
      <ErrorSectionNavigations2 />
      <ErrorSectionNavigations3 />
    </React.Fragment>
  );
}

