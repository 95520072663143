import React from "react";
import { Routes, Route } from "react-router-dom";

import IndexPage from "./pages/Index.js";

import ContactPage from "./pages/Contact.js";
import ProgramsPage from "./pages/Programs.js";
import ProgramPage from "./pages/Program.js";
import AboutPage from "./pages/About.js";

import ErrorPage from "./pages/Error.js";
import "./main_style.css";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop.js";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<IndexPage />} />

      

        <Route path="/programs" element={<ProgramsPage />} />
        {/*  <Route path="/program" element={<ProgramPage></ProgramPage>} /> */}
        <Route path="/program/:id" element={<ProgramPage />} />
        {/*  <Route path="/blod" element={<BlodPage />} /> */}
        <Route path="/about" element={<AboutPage />} />

        <Route path="/contact" element={<ContactPage />} />
        <Route path="/error" element={<ErrorPage />} />
      </Routes>
      <ScrollToTop></ScrollToTop>
    </div>
  );
}

export default App;
