import React from "react";
import { NavLink } from "react-router-dom";
import Madinacenter from "../../assets/images/madinacenter.svg";
export default function Nav() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const toggleClass = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <div
      style={{ minWidth: "100%" }}
      className="fixed min-w-full   z-50 border-b  bg-white  w-full "
    >
      <div className="container mx-auto overflow-hidden  ">
        <div className="relative z-20 flex items-center justify-between px-4 py-5 bg-transparent">
          <div className="w-auto ">
            <div className="flex flex-wrap items-center">
              <div className="w-auto ">
                <NavLink
                  to="/"
                  className={({ isActive, isPending, isTransitioning }) =>
                    [
                      isPending ? "pending" : " ",
                      isActive ? "active" : " ",
                      isTransitioning ? "transitioning" : "",
                    ].join(" ")
                  }
                >
                  <img
                    className="max-h-18"
                    /*     src="https://static.shuffle.dev/uploads/files/42/428466a1a4f3491d243e451b656104d1d732c574/Frame-1-1.svg" */
                    src={Madinacenter}
                    alt
                  />
                </NavLink>
              </div>
            </div>
          </div>
          <div className="w-auto hidden lg:block">
            <ul className="flex items-center ">
              <li className=" font-medium hover:text-gray-700">
                {/*  <a href="#">الرئيسية </a> */}

                <NavLink
                  to="/"
                  className={({ isActive, isPending, isTransitioning }) =>
                    [
                      isPending ? "pending" : " ",
                      isActive ? "active" : " ",
                      isTransitioning ? "transitioning" : "",
                    ].join(" ")
                  }
                >
                  الرئيسية
                </NavLink>
              </li>
              <li className="mr-9 font-medium hover:text-gray-700">
                <NavLink
                  to="/programs"
                  className={({ isActive, isPending, isTransitioning }) =>
                    [
                      isPending ? "pending" : " ",
                      isActive ? "active" : " ",
                      isTransitioning ? "transitioning" : "",
                    ].join(" ")
                  }
                >
                  البرامج
                </NavLink>
              </li>
              <li className="mr-9 font-medium hover:text-gray-700">
                <NavLink
                  to="/about"
                  className={({ isActive, isPending, isTransitioning }) =>
                    [
                      isPending ? "pending" : " ",
                      isActive ? "active" : " ",
                      isTransitioning ? "transitioning" : "",
                    ].join(" ")
                  }
                >
                  من نحن
                </NavLink>
              </li>
              <li className="mr-9 font-medium hover:text-gray-700">
                <NavLink
                  to="/contact"
                  className={({ isActive, isPending, isTransitioning }) =>
                    [
                      isPending ? "pending" : " ",
                      isActive ? "active" : " ",
                      isTransitioning ? "transitioning" : "",
                    ].join(" ")
                  }
                >
                  تواصلوا
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="w-auto">
            <div className="flex flex-wrap items-center">
              <div className="w-auto hidden mr-5 lg:block">
                <div className="inline-block">
                  {/*   <button
                    className="py-3 px-5 w-full hover:text-gray-700 font-medium rounded-xl bg-transparent transition ease-in-out duration-200  "
                    type="button"
                  >
                    עברית
                  </button> */}
                </div>
              </div>
              <div className="w-auto hidden lg:block">
                <div className="inline-block">
                  {/*     <button
                    className="py-3 px-5 w-full text-white font-semibold border border-indigo-700 rounded-xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200"
                    type="button"
                  >
                    انضموا الينا
                  </button> */}

                  <NavLink
                    to="/contact"
                    className={({ isActive, isPending, isTransitioning }) =>
                      [
                        isPending
                          ? "pending"
                          : " py-3 px-5 w-full text-white font-semibold border border-indigo-700 rounded-xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200",
                        isActive
                          ? "active"
                          : " py-3 px-5 w-full text-white font-semibold border border-indigo-700 rounded-xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200",
                        isTransitioning
                          ? "transitioning"
                          : "py-3 px-5 w-full text-white font-semibold border border-indigo-700 rounded-xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200",
                      ].join(" ")
                    }
                  >
                    انضموا الينا
                  </NavLink>
                </div>
              </div>
              <div className="w-auto lg:hidden">
                <button onClick={toggleClass}>
                  <svg
                    className="text-indigo-600"
                    width={51}
                    height={51}
                    viewBox="0 0 56 56"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width={56} height={56} rx={28} fill="currentColor" />
                    <path
                      d="M37 32H19M37 24H19"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            isMenuOpen
              ? "fixed top-0 right-0 bottom-0 w-4/6 sm:max-w-xs z-50"
              : "hidden fixed top-0 right-0 bottom-0 w-4/6 sm:max-w-xs z-50"
          }
        >
          <div className="fixed inset-0 bg-gray-800 opacity-80" />
          <nav className="relative z-10 px-9 pt-8 bg-white h-full overflow-y-auto">
            <div className="flex flex-wrap justify-between h-full">
              <div className="w-full">
                <div className="flex items-center justify-between -m-2">
                  <div className="w-auto p-2">
                    <NavLink
                      to="/"
                      className={({ isActive, isPending, isTransitioning }) =>
                        [
                          isPending ? "pending" : "  inline-block",
                          isActive ? "active" : "  inline-block",
                          isTransitioning ? "transitioning" : " inline-block",
                        ].join(" ")
                      }
                    >
                      <img
                        className="max-h-18"
                        /*    src="https://static.shuffle.dev/uploads/files/42/428466a1a4f3491d243e451b656104d1d732c574/Frame-1-1.svg" */
                        src={Madinacenter}
                        alt
                      />
                    </NavLink>
                  </div>
                  <div className="w-auto p-2 ">
                    <button
                      onClick={toggleClass}
                      className=" bg-indigo-50   rounded-full p-1"
                    >
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 18L18 6M6 6L18 18"
                          stroke="#111827"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-center py-16 w-full">
                <ul>
                  <li className="mb-12">
                    <NavLink
                      to="/"
                      className={({ isActive, isPending, isTransitioning }) =>
                        [
                          isPending
                            ? "pending"
                            : "  font-medium hover:text-gray-700",
                          isActive
                            ? "active"
                            : "  font-medium hover:text-gray-700",
                          isTransitioning
                            ? "transitioning"
                            : " font-medium hover:text-gray-700",
                        ].join(" ")
                      }
                    >
                      الرئيسية
                    </NavLink>
                  </li>
                  <li className="mb-12">
                    <NavLink
                      to="/programs"
                      className={({ isActive, isPending, isTransitioning }) =>
                        [
                          isPending
                            ? "pending"
                            : "  font-medium hover:text-gray-700",
                          isActive
                            ? "active"
                            : "  font-medium hover:text-gray-700",
                          isTransitioning
                            ? "transitioning"
                            : " font-medium hover:text-gray-700",
                        ].join(" ")
                      }
                    >
                      البرامج
                    </NavLink>
                  </li>
                  <li className="mb-12">
                    <NavLink
                      to="/about"
                      className={({ isActive, isPending, isTransitioning }) =>
                        [
                          isPending
                            ? "pending"
                            : "  font-medium hover:text-gray-700",
                          isActive
                            ? "active"
                            : "  font-medium hover:text-gray-700",
                          isTransitioning
                            ? "transitioning"
                            : " font-medium hover:text-gray-700",
                        ].join(" ")
                      }
                    >
                      من نحن
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/contact"
                      className={({ isActive, isPending, isTransitioning }) =>
                        [
                          isPending
                            ? "pending"
                            : "  font-medium hover:text-gray-700",
                          isActive
                            ? "active"
                            : "  font-medium hover:text-gray-700",
                          isTransitioning
                            ? "transitioning"
                            : " font-medium hover:text-gray-700",
                        ].join(" ")
                      }
                    >
                      تواصلوا
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="flex flex-col justify-end w-full pb-8">
                <div className="flex flex-wrap">
                  <div className="w-full mb-3">
                    <div className="block">
                      {/* <button
                        className="py-3 px-5 w-full hover:text-gray-700 font-medium rounded-xl bg-transparent transition ease-in-out duration-200"
                        type="button"
                      >
                        עברית
                      </button> */}
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="block text-center">
                      <NavLink
                        to="/contact"
                        className={({ isActive, isPending, isTransitioning }) =>
                          [
                            isPending
                              ? "pending"
                              : "py-3 px-5 w-full text-white font-semibold border border-indigo-700 rounded-xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200",
                            isActive
                              ? "active"
                              : " py-3 px-5 w-full text-white font-semibold border border-indigo-700 rounded-xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200",
                            isTransitioning
                              ? "transitioning"
                              : "py-3 px-5 w-full text-white font-semibold border border-indigo-700 rounded-xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200",
                          ].join(" ")
                        }
                      >
                        انضموا الينا
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}
