import React from "react";

export default function FeaturesSectionFeatures13() {
  return (
    <React.Fragment>
      <>
        <section className="pt-24 pb-28 bg-white overflow-hidden">
          <div className="container px-4 mx-auto">
            <h2 className="mb-7 text-6xl md:text-8xl xl:text-10xl font-bold font-heading text-center tracking-px-n leading-none">
              برامج المدينة{" "}
            </h2>
            <p className="mb-14 text-lg text-gray-600 font-medium text-center mx-auto md:max-w-2xl">
              البرامج التي قدمتها كلية المدينة سابقا
            </p>
            <div className="flex justify-center">
              <div className="inline-block">
                <img
                  className="mb-11 mx-auto transform hover:translate-y-3 transition ease-in-out duration-1000 rounded-3xl "
                  /* src="flaro-assets/images/features/data.png" */
                  src="https://www.almadina-college.com/media/editor/0151436001671521284.jpg"
                  alt
                  style={{maxHeight:400}}
                />
                <ul className="flex flex-wrap justify-center -m-8">
                  <li className="w-auto p-8">
                    <a
                      className="text-sm text-indigo-600 hover:text-indigo-700 font-semibold uppercase tracking-px"
                      href="#"
                    >
                      الصحن الطائر
                    </a>
                  </li>
                  <li className="w-auto p-8">
                    <a
                      className="text-sm text-gray-400 hover:text-gray-500 font-semibold uppercase tracking-px"
                      href="#"
                    >
                      المشروع السهل والبسيط
                    </a>
                  </li>
                  <li className="w-auto p-8">
                    <a
                      className="text-sm text-gray-400 hover:text-gray-500 font-semibold uppercase tracking-px"
                      href="#"
                    >
                      الحوسبه والعولمه
                    </a>
                  </li>
                  <li className="w-auto p-8">
                    <a
                      className="text-sm text-gray-400 hover:text-gray-500 font-semibold uppercase tracking-px"
                      href="#"
                    >
                      حلق في اعماق الفلسفه
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
