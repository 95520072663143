import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

import IndexSectionHeaders2 from "../components/headers/IndexSectionHeaders2";
/* import IndexSectionHeaders6 from "../components/headers/IndexSectionHeaders6"; */
import IndexSectionContact11 from "../components/contact/IndexSectionContact11";
import IndexSectionContact9 from "../components/contact/IndexSectionContact9";
import IndexSectionFooters7 from "../components/footers/IndexSectionFooters7";
import BlodSectionNumbers2 from "../components/numbers/BlodSectionNumbers2";
import FeaturesSectionFeatures7 from "../components/features/FeaturesSectionFeatures7";
import FeaturesSectionFeatures8 from "../components/features/FeaturesSectionFeatures8";
import HowItWorksSectionHowItWorks5 from "../components/how-it-works/HowItWorksSectionHowItWorks5";
import LogosSectionLogos1 from "../components/logos/LogosSectionLogos1";
import Nav from "../components/Nav/Nav";
import ProgramsData from "../data/programs.json";
const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <Nav />
      
      <IndexSectionHeaders2 />
      <BlodSectionNumbers2 />
      <FeaturesSectionFeatures8 />
      <FeaturesSectionFeatures7 />
      <HowItWorksSectionHowItWorks5 />
      <LogosSectionLogos1 />
      <IndexSectionFooters7 />
    </React.Fragment>
  );
}
