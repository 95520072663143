import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import BlodSectionNumbers1 from '../components/numbers/BlodSectionNumbers1';
import BlodSectionNumbers2 from '../components/numbers/BlodSectionNumbers2';
import BlodSectionTestimonials3 from '../components/testimonials/BlodSectionTestimonials3';
import BlodSectionBlog4 from '../components/blog/BlodSectionBlog4';
import BlodSectionTeams5 from '../components/teams/BlodSectionTeams5';
import BlodSectionTeams6 from '../components/teams/BlodSectionTeams6';
import BlodSectionFaqs10 from '../components/faqs/BlodSectionFaqs10';
import BlodSectionNewsletter9 from '../components/newsletter/BlodSectionNewsletter9';
import BlodSectionFaqs8 from '../components/faqs/BlodSectionFaqs8';
import BlodSectionTeams7 from '../components/teams/BlodSectionTeams7';
import IndexSectionHeaders2 from "../components/headers/IndexSectionHeaders2";
/* import IndexSectionHeaders6 from "../components/headers/IndexSectionHeaders6"; */
import IndexSectionContact11 from "../components/contact/IndexSectionContact11";
import IndexSectionContact9 from "../components/contact/IndexSectionContact9";
import IndexSectionFooters7 from "../components/footers/IndexSectionFooters7";
import Nav from "../components/Nav/Nav";

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Blod() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>

      
      <Nav />
      <IndexSectionContact11 />
      <IndexSectionContact9 />
      <BlodSectionNewsletter9 />
      <IndexSectionFooters7 />
    </React.Fragment>
  );
}

