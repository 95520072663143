import React from "react";
import P1 from "../../assets/images/p1.jpg";
import { Programs } from "../../data/Programs";
import { useParams } from "react-router-dom";

export default function TestSectionContact1() {
  let { id } = useParams();
  let Program = Programs.filter((element) => id.includes(element.id));
  let dontLineBreakDesc = [
    "27426",
    "32888",
    "32885",
    "26888",
    "25111",
    "25113",
    "22589",
    "22520",
    "21312",
    "21228",
    "21096",
    "20924",
    "20852",
    "20858"
  ];
  return (
    <React.Fragment>
      <>
        <section
          className="pt-24 pb-36 bg-white overflow-hidden"
          style={{ paddingTop: 140 }}
        >
          <div className="container px-4 mx-auto">
            <div className="mb-12 text-center md:max-w-xl mx-auto">
              <h2 className="mb-4 text-6xl md:text-7xl font-bold font-heading tracking-px-n leading-tight">
                {Program[0].name}{" "}
              </h2>
              <ul className="flex   items-center justify-center flex-wrap  ">
                {Program[0].audience.map((element) => {
                  return (
                    <li className="text-lg text-gray-600 font-medium leading-normal p-3  ">
                      <div className="mb-4">
                        <a className="mb-4 font-sans px-3 py-1.5 text-sm text-indigo-600 font-semibold bg-indigo-50 uppercase rounded-md ">
                          {element}
                        </a>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="flex flex-wrap xl:items-center -m-8">
              <div className="w-full md:w-1/2 p-8">
                <div className="max-w-max mx-auto overflow-hidden rounded-3xl">
                  <img
                    className="transform hover:scale-105 transition ease-in-out duration-1000"
                    /*  src="flaro-assets/images/contact/man.png" */
                    src={Program[0].Image}
                    alt
                  />
                </div>
              </div>
              <div className="w-full md:w-1/2 p-8 shadow rounded-3xl ">
                <p className="mb-4 text-sm font-bold uppercase tracking-px ">
                  <a className="mb-4 font-sans px-3 py-1.5 text-l text-indigo-600 font-semibold bg-indigo-white uppercase rounded-md shadow  ">
                    رقم البرنامج التسلسلي - מספר תוכנית
                  </a>
                </p>
                <ul className="mb-4">
                  <li className=" ">
                    <a className="mb-4 font-sans px-3 py-1.5 text-l text-indigo-700 font-semibold bg-indigo-white uppercase rounded-md   ">
                      {Program[0].id}
                    </a>
                  </li>
                  {/* <li className="text-lg text-gray-600 font-medium leading-normal">
                    support@gmail.com
                  </li> */}
                </ul>

                <p className="mb-4 text-sm font-bold uppercase tracking-px">
                  {" "}
                  <a className="mb-4 font-sans px-3 py-1.5 text-l text-indigo-600 font-semibold bg-indigo-white uppercase rounded-md shadow  ">
                    مختصر
                  </a>
                </p>

                {/*     <p className=" mb-4 text-lg text-gray-600 font-medium leading-normal  mx-auto">
                  על מנת להכין בוגר שיכול להתמודד עם אתגרי העידן החדש אנחנו
                  מאמינים שיש להעצים את מיומנויות המאה 21, אנו רואים ברובוטיקה
                  כלי מאוד חזק להשגת מטרה זו. בניית רובוטים המשרתים מטרה ופותרים
                  בעיה בתחום החיים מפתחת אצל הילד מיומנות חקר בעיות, לימוד עצמי,
                  מציאת פתרונות יצירתיים, עבודה בצוות, חשיבה חשיבה מסדר גבוהה,
                  חשיבה ביקורתית, עמידה מול קהל לוגית, להצגת המוצר, ורכישת שפת
                  תכנות.
                </p> */}

                <p className=" mb-4 text-lg text-gray-600 font-medium leading-normal  mx-auto">
                  {dontLineBreakDesc.includes(Program[0].id)
                    ? Program[0].desc
                    : Program[0].desc.split("\n").map((str) => <p>{str}</p>)}
                </p>

                <p className="mb-4 text-sm font-bold uppercase tracking-px">
                  <a className="mb-4 font-sans px-3 py-1.5 text-l text-indigo-600 font-semibold bg-indigo-white uppercase rounded-md shadow  ">
                    تواصلوا على
                  </a>
                </p>
                <ul className="">
                  <li className="text-lg text-gray-600 font-medium leading-normal">
                    <a
                      className="text-gray-600 font-medium leading-relaxed block"
                      href="tel:+972509391320"
                    >
                      050-939-1320
                    </a>
                  </li>
                  <li className="text-lg text-gray-600 font-medium leading-normal">
                    <a
                      className="text-gray-600 font-medium leading-relaxed block"
                      href="tel:+97249994747"
                    >
                      04-999-4747
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
