import React from "react";

export default function FeaturesSectionFeatures7() {
  return (
    <React.Fragment>
      <>
        <section className="pt-32 pb-36 bg-indigo-600 overflow-hidden">
          <div className="container px-4 mx-auto">
            <div /* className="md:max-w-2xl" */>
              <h2 className="mb-20 text-6xl md:text-8xl xl:text-10xl text-white font-bold tracking-px-n leading-none text-center">
                اختر البرنامج الأنسب لاحتياجاتك لتُحقق أفضل النتائج.
              </h2>
              {/* <p className="mb-36 text-white text-opacity-70">
                من لىلا ااال للرر ررر لل
              </p> */}
            </div>
            <div className="flex flex-wrap -m-3">
              <div className="w-full md:w-1/2 p-3">
                <div className="p-7 bg-white border border-gray-900 rounded-3xl">
                  <div className="flex flex-wrap -m-4">
                    <div className="w-auto p-4">
                      {/*  <svg
                        width={28}
                        height={28}
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                          className=" bg-indigo-100  rounded-md"
                      >
                        <path
                          d="M4.66669 5.83329C4.66669 5.18896 5.18902 4.66663 5.83335 4.66663H22.1667C22.811 4.66663 23.3334 5.18896 23.3334 5.83329V8.16663C23.3334 8.81096 22.811 9.33329 22.1667 9.33329H5.83335C5.18902 9.33329 4.66669 8.81096 4.66669 8.16663V5.83329Z"
                          stroke="#111827"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M4.66669 15.1666C4.66669 14.5223 5.18902 14 5.83335 14H12.8334C13.4777 14 14 14.5223 14 15.1666V22.1666C14 22.811 13.4777 23.3333 12.8334 23.3333H5.83335C5.18902 23.3333 4.66669 22.811 4.66669 22.1666V15.1666Z"
                          stroke="#111827"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M18.6667 15.1666C18.6667 14.5223 19.189 14 19.8334 14H22.1667C22.811 14 23.3334 14.5223 23.3334 15.1666V22.1666C23.3334 22.811 22.811 23.3333 22.1667 23.3333H19.8334C19.189 23.3333 18.6667 22.811 18.6667 22.1666V15.1666Z"
                          stroke="#111827"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg> */}
                      <svg
                        className=" bg-indigo-50  rounded-md"
                        width={28}
                        height={28}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                      >
                        <path
                          stroke="#111827"
                          strokeWidth={1.5}
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M8.25 3v1.5M4.5 8.25H3m18 0h-1.5M4.5 12H3m18 0h-1.5m-15 3.75H3m18 0h-1.5M8.25 19.5V21M12 3v1.5m0 15V21m3.75-18v1.5m0 15V21m-9-1.5h10.5a2.25 2.25 0 0 0 2.25-2.25V6.75a2.25 2.25 0 0 0-2.25-2.25H6.75A2.25 2.25 0 0 0 4.5 6.75v10.5a2.25 2.25 0 0 0 2.25 2.25Zm.75-12h9v9h-9v-9Z"
                        />
                      </svg>
                    </div>
                    <div className="flex-1 p-4">
                      <h3 className="mb-3 text-lg font-semibold">
                        البرامج التكنولوجية{" "}
                      </h3>
                      <p className="text-gray-600 font-medium">
                        {/*          تتمحور هده البرامح حول قدرات الطلاب على حل المشاكل
                        الهندسيه وملاحظة امور ومزايا تبدو خفية عند التظر اليها
                        في الوهلت الاولى */}
                        تتمحور هذه البرامج حول قدرات الطلاب على حل المشاكل
                        الهندسيّة وملاحظة امور ومزايا تبدو خفية عند النظر اليها
                        في الوهلة الاولى
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2 p-3">
                <div className="p-7 bg-white border border-gray-900 rounded-3xl">
                  <div className="flex flex-wrap -m-4">
                    <div className="w-auto p-4  ">
                      {/* <svg
                        width={28}
                        height={28}
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className=" bg-indigo-100  rounded-md"
                      >
                        <path
                          d="M3.5 11.6667H24.5M8.16667 17.5H9.33333M14 17.5H15.1667M7 22.1667H21C22.933 22.1667 24.5 20.5997 24.5 18.6667V9.33337C24.5 7.40038 22.933 5.83337 21 5.83337H7C5.067 5.83337 3.5 7.40038 3.5 9.33337V18.6667C3.5 20.5997 5.067 22.1667 7 22.1667Z"
                          stroke="#111827"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg> */}

                      <svg
                        className=" bg-indigo-50  rounded-md  "
                        width={28}
                        height={28}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="#111827"
                          strokeWidth={1.5}
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                        />
                      </svg>
                    </div>
                    <div className="flex-1 p-4">
                      <h3 className="mb-3 text-lg font-semibold">
                        البرامج التربوية والاجتماعية
                      </h3>
                      <p className="text-gray-600 font-medium">
                        {/*     تتمحور حول التواصل الاجتماعي بين الطلبه ويكون التركيز
                        علىجاب الحسي والدكاء الحسي الدي يمتلك المشتركون في
                        البرامج */}
                        تتمحور حول التواصل الاجتماعي بين الطلبة ويكون التركيز
                        على الجانب الحسّي والذكاء الحسّي الذي يمتلكه
                        المشتركون في البرامج
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
