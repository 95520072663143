import P1 from "../assets/images/p1.png";
import P2 from "../assets/images/p2.png";
import P3 from "../assets/images/p3.png";
import P4 from "../assets/images/p4.png";
import P5 from "../assets/images/p5.png";
import P6 from "../assets/images/p6.png";
import P7 from "../assets/images/p7.png";
import P8 from "../assets/images/p8.png";
import P9 from "../assets/images/p9.png";
import P10 from "../assets/images/p10.png";
import P11 from "../assets/images/p11.png";
import P12 from "../assets/images/p12.png";
import P13 from "../assets/images/p13.png";
import P14 from "../assets/images/p14.png";
import P16 from "../assets/images/p16.jpg";
import P17 from "../assets/images/p17.jpg";
import P18 from "../assets/images/p18.jpg";
import P19 from "../assets/images/p19.jpg";
import P20 from "../assets/images/p20.jpg";
import P21 from "../assets/images/21.jpg";
import P22 from "../assets/images/22.jpg";
import P23 from "../assets/images/23.jpg";
import P24 from "../assets/images/24.jpg";


export const Programs = [
  {
    id: "20852",
    name: "‫רובוטיקה ו ‪ 10-‬המיומניות‬",
    desc: `על מנת להכין בוגר שיכול להתמודד‬‫עם אתגרי העידן החדש אנחנו‬‫מאמינים שיש להעצים את מיומנויות‬
‫המאה ‪ ,21‬אנו רואים ברובוטיקה כלי‬‫מאוד חזק להשגת מטרה זו‪.‬‬‫בניית רובוטים המשרתים מטרה‬‫ופותרים בעיה בתחום החיים מפתחת‬
‫אצל הילד מיומנות חקר בעיות‪‬‬‫לימוד עצמי‪ ,‬מציאת פתרונות‬
‫יצירתיים‪ ,‬עבודה בצוות‪ ,‬חשיבה‬
‫לוגית‪ ,‬חשיבה מסדר גבוהה‪,‬‬
‫חשיבה ביקורתית‪ ,‬עמידה מול קהל‬
‫ורכישת שפת‬
‫להצגת המוצר‪,‬‬
‫תכנות‪.‬‬
`,
    audience: [
      `‫גן חובה עד כיתות י"ב‬`,
      `‫תלמידים מצטיינים‬`,
      `‫תלמידי חינוך מיוחד‬`,
    ],
    tech: true,
    Image: P1,
  },
  {
    id: "20858",
    name: "‫רחפנים כל אחד יכול‬",
    desc: `רחפנים היום הם כלי הטיס הנחקרים‬
‫ביותר במעבדות המובילות בעולם‬
‫העוסקות במחקר ופיתוח בתחומים‬
‫שוני‪ ,‬משלוחים‪ ,‬צילום ולמטרות‬
‫ביטחוניות ‪.‬‬
‫המשתתפים בתוכנית ילמדו בצוותים‬
‫קטנים ‪,‬הלמידה תהיה למידה‬
‫באמצעות פרויקטים במהלך התוכנית‬
‫המשתתפים יחשפו למגוון של תכנים‬
‫‪:‬שפה אנגלית ‪ ,‬הנדסה‪ ,‬מחשבים‪,‬‬
‫תעופה‪,‬רובוטיקה‬
‫אלקטרוניקה‪,‬‬
‫ואווירונאוטיקה‪ .‬המשתתפים יתנסו‬
‫בפתרון בעיות מורכבות ‪,‬יחשפו‬
‫לאתגרים חדשים ויחוו למידה‬
‫משמעותית המשלבת רכישת ידע‬
‫חדש ויישומו ‪,‬העצמת תחושת‬
‫המסוגלות העצמית של המשתתפים‪,‬‬
‫ירכשו מיומנויות חקר עם לא מעט‬
‫הנאה‪.‬‬
‫המשתתפים ילמדו להרכיב‪ ,‬לתכנת‬
‫ולהטיס רחפנים‪.‬‬
`,
    audience: [`‫תלמידי כיתות א' עד י"ב‬`],
    tech: true,
    Image: P2,
  },
  {
    id: "20924",
    name: `מנהיגות צעירה מקדמת‬
‫גישור ופתרון קונפליקטים
`,
    desc: `גישור הינו תהליך לפתרון מחלוקות‬
‫בין אנשים‪ .‬הייחודי בגישור וסוד כוחו‬
‫לעומת הליכים אחרים ‪ ,‬הוא שהפתרון‬
‫אליו מגיעים בסיום התהליך הוא‬
‫פתרון המוסכם על כל הצדדים‬
‫שמושג בהסכמה של הצדדים ולא‬
‫בכפייה‪.‬‬
‫בתוכנית תהיה שימת דגש רב על‬
‫ערכים ‪,‬חיזוק המיומנות הרכות בקרב‬
‫המשתתפים ותפתח הזהות החברתית‬
‫שלהם לשילובם בקהילה ובחברה ‪.‬‬
‫בתוכנית תהיה חשיפה נרחבת למגוון‬
‫כלים זמינים לטובת עיצוב דור‬
‫שמאמין בסובלנות ובהידברות כדרך‬
‫חיים ‪.‬‬
`,
    audience: [`‫בני נוער בגילאים ‪12-18‬‬`, `הורים`, `‫צוותי הוראה‬`],
    tech: false,
    Image: P3,
  },
  {
    id: "21096",
    name: `העולם מקרוב ( מציאות מדומה VR ואנטלגנציה מלאכותית AI)`,
    desc: `התכנית בנויה כך שתספק חוויה‬
‫לימודית מיוחדת המושכת תשומת לב‬
‫התלמידים ותוביל למהפכה בעולם‬
‫ההוראה‪ .‬התוכנית מתאימה לכל‬
‫הגילאים ולכל הרמות ואף חובה‬
‫לתלמידים מתקשים ותלמידים של‬
‫החינוך המיוחד‪ .‬לתכנית מצורף‬
‫סילבוס מובנה לפי נושאים הנבחרים‬
‫בקפדנות ומתואמים לקהל היעד‪ ,‬ניתן‬
‫להוסיף תכנים לפי הדרישות של בתי‬
‫הספר או המוסדות ‪.‬כל הנושאים‬
‫בתוכנית נתמכים ע"י טכנולוגיית‬
‫המציאות המדומה ומותאמים לפי‬
‫תכנית הלימודים ולכל שכבת גיל‪.‬‬
‫התוכנית מעודדת למידה עצמית‬
‫‪,‬משמעותית ‪,‬פעילה וללא הפרעות‬
‫ורעשים תוך שליטה מלאה של המדריך‬
‫או המורה על הנעשה בכיתה ‪.‬‬
`,
    audience: [
      `‫גנים ‪ :‬החל מ‪4 -‬שנים‬`,

      `‫בתי ספר‪ :‬א'‪-‬י"ב‬`,
      `‫צוות חינוכי‬`,
    ],
    tech: true,
    Image: P4,
  },
  {
    id: "21228",
    name: `‫טיקטוק בטיק טאק‬`,
    desc: `אנו נמצאים בעידן בו כל יום נוצרים‬
‫מיליונים על מיליונים של תכנים במערכות‬
‫תקשורת ומידע שונות ומשונות ‪,‬רשתות‬
‫חברתיות הפכו להיות חלק בלתי נפרד‬
‫מחיינו‪ .‬אנשים בכל הגילאים מחוברים‬
‫ומקשורים ברשתות חברתיות שונות‬
‫‪,‬שגוזלות המון מזמנם היומיומי וגם‬
‫מכספם ‪.‬‬
‫פעמים רבות אנו שומעים את המונחים‬
‫"התוכן הוא המלך" ו "התוכן " הוא הדבר‬
‫החשוב ביותר בשיווק של אנשים ‪ ,‬חברות‬
‫ארגונים‪ ,‬אבל למעשה אף אחד לא עוצר‬
‫ומסביר מה זה תוכן טוב ואיך עושים תוכן‬
‫טוב למערכות דיגיטליות ‪.‬‬
‫התוכית תחשוף את המשתתפים לעולם‬
‫המושגים של עולם התקשורת המשתנה ‪,‬‬
‫תטפח ותקדם את היכולת להתמצא‬
‫במרחב המקוון‪ ,‬להכיר את מאפייניו‪,‬‬
‫להעריך את האיכות והאמינות של מסרים‬
‫עתירי צורות ייצוג מתוך זיהוי מאפייני‬
‫המידע‪ .‬המשתתפים יתנסו ביצירת תוכן‬
‫מולטימדיה באמצעות כלים דיגיטליים‬
‫מגוונים וקידום תקשורת בין‪-‬אישית‬
‫וקבוצתית באופן יעיל ומותאם לצרכים‪.‬‬
`,
    audience: [`‫בני נוער בגילאי כיתות (ז' –י"ב)‪.‬‬`],
    tech: true,
    Image: P5,
  },
  {
    id: "21312",
    name: `ליווי מנהלים ובתי ספר‬
‫ושיפור הישגים בעידן הגפ"ן‬
`,
    desc: `המטרת תוכנית זו‪ ,‬ללוות ולסייע למנהלים‬
‫בבניית תוכנית עבודה מקושרת משאבים‬
‫ומכוונת תוצאות – בראייה תלת שנתית!!‬
‫דגשי הליווי‪ :‬שילוב תוכנית עבודה לשיפור‬
‫הישגים ושיפור אח"מ‪.‬‬
‫סיוע וליווי בהיבטים החשבונאיים של‬
‫הטיפול בתקציב גפ"ן‪.‬‬
‫ניתן להפעיל את התוכנית בתהליך מקוצר‬
‫או כתוכנית ללוי שנתי‪.‬‬
`,
    audience: [
      `‫מנהלי בתי ספר‬`,
      `‫צוותי הוראה מובילים‬`,
      `‫מנהלי מחלקות חינוך‬`,
    ],
    tech: false,
    Image: P6,
  },
  {
    id: "22520",
    name: `העשרה בערוץ החלומות‬
‫(חוגי העשרה)‬
`,
    desc: `חוגי העשרה פותחים בפניו של הילד או‬
‫הנער עולם מגוון וססגוני אשר בו‬
‫מתאפשרת הבחירה לפי התחביבים‬
‫ותחומי ההתעניינות של הנער דבר שאין‬
‫ביכולתו לעשות בתוך כותלי בית הספר ‪.‬‬
‫חוגי העשרה מסייעים לילדים ובני נוער‬
‫לנצל את שעות הפנאי אחרי יום הלימודים‬
‫ובחופשות ומביאים להעלאת המוטיבציה‬
‫עקב‬
‫המסוגלות‬
‫ותחושת‬
‫האישית‬
‫האפשרות של הבחירה החופשית לפי‬
‫תחביב או תחום עניין ‪.‬‬
‫מדעים והנדסה‬
‫מדען צעיר‪/‬חשבון וחשיבה כמותית‪/‬‬
‫רובוטיקה‪/‬דרונז(רחפנים)‪ /‬אווירונאוטיקה‬
‫וחלל ‪ /‬מדע בעלי חיים ‪ /‬המהנדס הצעיר ‪/‬‬
‫מתמטיקה מדעית ‪ /‬מדע וקיימות‪.‬‬
‫אומנות‬
‫צילום‪ /‬אומנות הציור על קירות ציבוריים‬
‫‪/‬תיאטרון בובות ‪ /‬להקת שירה ‪ /‬זומבה‬
‫עם‪/‬‬
‫וריקודי‬
‫ותנועה‪/‬ריקוד‬
‫‪/‬צליל‬
‫פלסטלינה ומוטוריקה ‪ /‬שירה ומנגינה ‪/‬‬
‫פיסול בחימר ופסיפס‬
`,
    audience: [`‫ג'‪-‬ו'‬`, `‫בחירה ‪ :‬לפי תחומי עניין של התלמיד‬`],
    tech: false,
    Image: P7,
  },
  {
    id: "22589",
    name: `עוברים בירוק‬
‫(חינוך תעבורתי מגיל צעיר)‬
`,
    desc: `מידי יום אנו שומעים על אסון חדש או יותר‬
‫כתוצאה מתאונות הדרכים‪ ,‬לתאונות‬
‫הדרכים סיבות רבות כמו בעיית התשתיות‪,‬‬
‫לקויות ברכב עצמו‪ ,‬והתנהלות האנושית‬
‫הכתבות‬
‫הסקרים‬
‫לפי‬
‫בכבישים‪,‬‬
‫והמאמרים המעידים שהאחוז הגדול מבין‬
‫הגורמים שהוזכרו הוא באחריות הגורם‬
‫האנושי‪.‬‬
‫בני הנוערהם אחד ממשתמשי הדרך‪,‬‬
‫חלקם הולכי רגל‪ ,‬רוכבי אופניים‪ ,‬אופניים‬
‫חשמליים וקורקינט‪ ,‬והם הופכים להיות‬
‫נהגים בעתיד הקרוב‪ ,‬העלאת המודעות‬
‫ולמידת כללי ההתנהגות הנכונה בגיל קטן‬
‫תביא לתוצאות טובות יותר‪.‬‬
‫התכנית תפעל בכמה מישורים‪ ,‬הכרת‬
‫החוקים‪ ,‬קבלת החלטות נכונות בזמן לחץ‪,‬‬
‫מתן כבוד לזכויות הזולת‪ ,‬סבלנות‪ ,‬למידה‬
‫מניסיון האחרים ‪.‬‬
`,
    audience: [`‫תלמידים מכיתה ז‪ -‬י"ב‬`, `‫הורים‬`],
    tech: false,
    Image: P8,
  },
  {
    id: "25113",
    name: `עדשה צעירה ‪ -‬ צילום‬
‫להעצמה רגשית‬
`,
    desc: `התוכנית מורכבת מהתנסות ולמידה‬
‫מעשית של צילום ע"י אנשי מקצוע‬
‫ומומחים בתחום הצילוםומשולבת עם‬
‫למידה עיונית ‪,‬הלמידה תחולק‬
‫קבוצתית‬
‫וגם‬
‫אישית‬
‫ללמידה‬
‫ובמרחבים שונים לפי צורכי הקבוצה‬
‫במסגרת התוכנית נגע בבעיות רגשית‬
‫והתמודדות עם בעיות חברתיות‬
‫ובבחינת הדרכים להצגת בעיות אלה‬
‫דרך שימוש במצלמה‪ .‬המשתתפים‬
‫ילמדו את שפת הצילום‪ ,‬יתנסו בצילום‬
‫מעשי המבטא את חייהם וחוויותיהם‪,‬‬
‫המשתתפים יובילו פרויקט חברתי או‬
‫תערוכה המציגה את קולם החברתי ‪.‬‬
`,
    audience: [`כיתות ז ‪-‬י"ב‬`, `‫חינוך מיוחד וחינוך רגיל‬`],
    tech: true,
    Image: P9,
  },
  {
    id: "25111",
    name: `טיפול באומנות‬
‫אומנות ‪,‬שפת העצמה שקטה‬
`,
    desc: `טיפול באומנות היא דרך להגשמת מענה‬
‫והינה דרך להעצמה חברתית רגשית‪.‬‬
‫טיפול באומנות הנו מענה חובה להבנה‪,‬‬
‫לקבלה ולהכלת תלמידים עם צרכים‬
‫ייחודיים ושונים ‪,‬תלמידים עם מוגבלויות‪,‬‬
‫תלמידים עם לקויות למידה‪ ,‬תלמידים‬
‫עם קשיים לימודיים‪ ,‬רגשיים והתנהגותיים‬
‫‪ .‬התוכנית מכוונת לקדם את מצבם‬
‫הלימודי‪ ,‬רגשי‪ ,‬חברתי וההתנהגותי של‬
‫התלמידים לאחר תהליך איתור ומיפוי‬
‫שעוברים התלמידים או בני הנוער‬
‫הטיפול עושה שימוש באומנות חזותית (‬
‫פיסול‪ ,‬ציור‪ ,‬הדפס‪ ,‬מריחה ועוד ) ‪.‬‬
‫"טיפול באומנות" מתבסס על האמונה‬
‫בכוחה של היצירה האמנותית מהווה גשר‬
‫ליצירת קשר בין המטפל והמטופל והיא‬
‫מאפשרת שיח בשפה הלא מילולית‬
‫ומעצים התמודדות מול הקשיים הרגשיים‬
‫והחברתיים‪.‬‬
`,
    audience: [`‫כיתות ג' ‪-‬ט'‬`],
    tech: false,
    Image: P10,
  },
  {
    id: "26888",
    name: `סדנאות טיפוליות להעצמת‬
‫ילדים ובני נוער‬
`,
    desc: `התוכנית מציעה מגוון סדנאות‬
‫טיפוליות להעצמת בני נוער‪:‬‬
‫סדנא לעיצוב התנהגות‬
‫טיפול באמנות‬
‫העצמה תלמידים בסדנת פוטו‬
‫תרפיה וטיפול רגשי‬
‫סדנא טיפול בבישול‬
‫סדנת לטיפול בדרמה‬
‫סדנא לטיפול בעזרת בעלי חיים‬
`,
    audience: [`‫כיתות א' ‪-‬י'‬`],
    tech: false,
    Image: P11,
  },
  {
    id: "32885",
    name: `‫מיומנויות לתעסוקה‬`,
    desc: `רכישת מיומנויות תעסוקתיות בעידן‬
‫הטכנולוגי ‪:‬‬
‫תיקון וטיפול בטלפונים ניידים‬
‫וטאבליטים‬
‫נגרות עתידית‬
‫תקשוב ובניית רשתות‬
‫עיצוב גרפי‬
‫איפור ועיצוב שיער‬
‫עיצוב תכשיטים וסטייל‬
‫בישול והכנת סלט‬
`,
    audience: [`‫תלמידים מכיתות ז'עד י"ב‬`],
    tech: true,
    Image: P12,
  },
  {
    id: "32888",
    name: `‫דו קיום למען הקיום‬`,
    desc: `תכנית חינוכית‪-‬ערכית–חברתית אשר‬
‫מיועדת לתלמידים ובניהנוער משני‬
‫המגזרים ‪ .‬בתוכנית ניתנת לבני הנוער‬
‫ההזדמנות להשמיע את קולם ולשתף‬
‫בתחושותיהם ורגשותיהם כלפי האחר‬
‫ותרבותו ‪ .‬המשתתפים יחשפו למגוון‬
‫רחב של פעילויות העצמה כקבוצה‬
‫שמובילה לשינוי בחברה ‪ .‬המשתתפים‬
‫ישמעו סיפורי חיים אישיים של‬
‫מנהיגים ישראלים אשר האמינו בדו‬
‫קיום ופעלו למענו כל זה באווירה של‬
‫אחווה ‪,‬סובלות ‪,‬סבלנות וקבלת האחר‬
‫לא משנה מי הוא ומאיפה הוא מגיע ‪.‬‬
`,
    audience: [`‫תלמידים מכיתות ז'עד י"ב‬`],
    tech: false,
    Image: P13,
  },
  {
    id: "27426",
    name: `מורשת והכרת הארץ‬
‫(אתרים חשובים בארץ)‬
`,
    desc: `סיורים להכרת הארץ והאתרים‬
‫החשובים עבור התלמידים כיעד‬
‫לשמירה על מורשת ארוכת טווח‬
‫והרגשת השייכות‪ ,‬שינוי בדפוסי‬
‫התנהגות והיחס כלפי האתרים‬
‫החשובים בארץ מעלה את המודעות‬
‫ההסטורית העמים שהיו לפנינו‪ ,‬ועוד‪.‬‬
‫התוכנית תועבר בצורת סדנאית‬
‫המשלבת חומרים תיאורטיים לקראת‬
‫סיורים באתר עצמו‪ ,‬לאחר כל מפגש‬
‫תיאורטי יועבר סיור מודרך במטרה‬
‫שהתלמידים יראו את האתר ואת‬
‫הדברים החשובים שקיבלו במפגש‪.‬‬
`,
    audience: [`‫תלמידים מכיתות ד' עד י"ב‬`],
    tech: false,
    Image: P14,
  },

  {
    id: "23706",
    name: `מקיימים בכיף`,

    desc: `الكرة الأرضية  هي بيتنا، والكثير من الجهود تبذل لتقوية التربية البيئية في المجتمعات وخاصة بين أبنائنا. وقد أصبح اهتمام الوزارة بالبيئة في سُلم الولويّان. ومن الأمور الّتي يُقاسُ بها نجاحُ المَدارس مدى إسهامها في تطوير البيئة والحفاظ عليها. 
\n-------------------\n
اهداف البرنامج:
\n-------------------\n
•	بناء مهارات التعلم والبحث المستقل لدى الطلاب من خلال تعزيز قدرة التفكير النقدي.
\n
•	الربط بين النشاط الاجتماعي والوعي بالفضاء البيئي المحلي والعالمي من أجل إحداث تغيير سلوكي.
\n
•	الانكشاف لخصائص ومصادر الأزمات البيئية مثل تلوث المياه، التخلص من القمامة، استخدام البلاستيك في إسرائيل والمجتمع العربي.
\n
•	رفع مستوى الوعي بين الطلاب والمعلمين حول أهمية استخدام الطاقة المتجددة وخاصة الطاقة الشمسية من خلال التعلم النظري والتجارب من خلال المشروع النهائي.
\n
•	تعزيز قدرة الطلاب على التأثير في قيادة مشاريع اجتماعية وبيئيّة من خلال استخدام الطاقة الشمسية.
\n

	من خاصية هذا البرنامج انه يمكنه الاندماج مع اهداف ورؤيا المدرسة واحتياجاتها في بناء خطة وسيرورة العمل تكون بالملائمة أيضا مع الأهداف التربوية القابلة للتنفيذ.

`,
    audience: [
      `  طلاب ابتدائي : ثالث-سادس`,
      `                          طلاب اعدادي:   سابع -تاسع`,
      `                          طلاب ثانوي:   عاشر-ثاني عشر `,
    ],
    tech: false,
    Image: P16,
  },
  {
    id: "48178",
    name: `برنامج  قيادة طلابية مدرسية `,

    desc: `العمل الجماعي والقيادي في البيئة الطلابية من اهم الأهداف في المدرسة فمع هذا العمل ننمي مجتمع مترابط صاحب قيم عالية . من نتائج برنامج كهذا كيفية بناء مشاريع مجتمعية ومجالس طلابية . 
\n-------------------\n
مهارات: 
\n-------------------\n
•	الوعي الذاتي.\n
•	الوعي الاجتماعي.\n
•	الثقة بالنفس .\n
•	القيادة .\n
\n-------------------\n
اهداف البرنامج
\n-------------------\n
•	لتشجيع المشاركة الاجتماعية والمساهمة في المجتمع الطلابي المدرسي .\n
•	تنمية مهارات القيادة والمبادرة لدى الطلاب.\n
•	السماح للطلاب بأخذ أدوار قيادية وقيادة التغييرات الإيجابية في المدرسة.\n
•	زيادة إحساس الطلاب بالقدرة الشخصية والفعالية.\n

`,
    audience: [
      `طلاب  صف بستان `,
      `طلاب ابتدائي : اول-سادس`,
      `                          طلاب اعدادي : سابع -تاسع`,
    ],
    tech: false,
    Image: P17,
  },
  {
    id: "9364",
    name: `א.מ.צ.ע `,

    desc: `نجمع بين تعزيزِ الجانِبِ النفسيّ والجانبِ التّحصيلِي، فالحالة النفسية لدى الطالب مهمة جدًا. كما ويُمكنُ أن نُحدّد وضعَه النّفسيّ والدّاخليِّ من تعبير يظهره كما ويُمكن الإشارَة إلأى وجوده في ضائقة ما . مثلًا؛ الفنُّ بأنواعه يعتبر من الأساليب التي تضفي شعورًا بالراحة النفسية لدى الانسان.
في هذا البرنامج، نأخذ الطالبَ في رحلةٍ ميدانية تتضمن التفاعل مع المجتمع والمُحيط التي يتواجد بها  بطرق جديدة مثل: العلوم، والروبوتات، والعلاج بالفن، وحتى العلاج عبر شبكات التواصل الاجتماعي.
\n-------------------\n
اهداف البرنامج
\n-------------------\n
•	التمكين الشخصي.\n
•	حل مشكلات الصحة النفسية لدى الطلاب.\n
•	الوقوف علىا لامَشاكل الّتي يمرُّ بِها الطالبُ\n
•	اكتساب مهارات التفكير الإيجابي والقدرة على التأثير.\n
•	تخفيف التوتر.\n
•	بناء منظومة تدريبية بين الشباب والمُحيط والمجتمع.\n
•	تمهيد الطريق للتغيير الاجتماعي ودمج الطلاب في التغيير الذي طال انتظاره في المجتمع العربي.\n

`,
    audience: [
      `طلاب  صف بستان `,
      `طلاب ابتدائي  اول-سادس`,
      `طلاب اعدادي : سابع -تاسع`,
      `                          طلاب ثانوي : عاشر-ثاني عشر `,
      `طلاب مع مشاكل سلوكية/طلاب بضائقة`,
      `طلاب عسر تعليمي /وممكن للطاقم `,
    ],
    tech: false,
    Image: P18,
  },
  {
    id: "23727",
    name: `برنامج  فن الإحساس `,

    desc: `الدراما، المسرح والفنون من المواضيع التي تستعمل في المجال العلاجي خاصة مع الصغار بالسن مثلا:
بالتعامل مع الانتقالات، منع النشور من المدرسة.
من مميزات هذا البرنامج تحديد الأهداف الشخصية وقوة الشراكات وتحديد مكان الشريك العاطفي والوعي الذاتي بدور الشريك في كافة تفاصيل الوظيفة سواء كانت عاطفية أو عملية أو أي دور أساسي آخر.
\n-------------------\n
اهداف البرنامج
\n-------------------\n
•	التغلب على مشاعر الفشل والإقصاء من خلال تعزيز الثقة بالنفس لدى كل طالب.\n
•	زيادة الانتماء للمدرسة من خلال تعزيز المساحة الآمنة للتعبير عن الذات وتمكين تعزيز المشاعر الإيجابية.\n
•	تعزيز القدرة على التعامل مع صعوبات التعلم من خلال مغازلة مشاعر الكفاءة الذاتية والتعلم المستقل.\n
•	تقييم التقدم الأكاديمي للطالب والعلاقة بينه وبين الفضاء المدرسي.\n
`,
    audience: [
      `  طلاب ابتدائي : ثالث-سادس`,
      `                          طلاب اعدادي:   سابع -تاسع`,
      `   طلاب ثانوي : عاشر- حادي عشر `,
    ],
    tech: false,
    Image: P19,
  },

  {
    id: "23725",
    name: `         برنامج  باب للتوعية والمشاعر `,

    desc: `بسبب ما تمر به المدارس في السنوات الأخيرة من تحديات وصعوبات ( الكورونا والحُروب...) والحاجة الى نقل التعليم عن بعد في فترات معينة , نما فهم الحاجة لتنمية القدرات التعليمية لدى الطلاب بشكل عام ودمج الجانب الاجتماعي والعاطفي لكل طالب أن هناك العديد من الأزمات التي تضعف بشكل كبير قدرة الطلاب على التواصل والتركيز بسبب نقص الأدوات والقيم والمهارات التي تمكنهم من التعامل مع المواقف الطارئة.
                            وايضا الطاقم التعليمي وأولياء الأمور أيضًا وجدوا أنفسهم في التحدي المتمثل في التعامل مع نقص المهارات الاجتماعية والعاطفية والسلوكية لدى الطلاب وتوفير فرصة للتعبير عن الذات والفهم المتعمق لأنفسهم.
\n-------------------\n
اهداف البرنامج
\n-------------------\n
• التعارف العميق مع الذات.\n
•	تحسين مهارات التواصل بين الأشخاص من أجل القضاء على التواصل العدواني.\n
•	تعزيز شعور الطلاب بالقدرة من خلال علاج احترام الذات.\n
•	تعزيز المسؤولية الجماعية تجاه الفضاء المجتمعي مع تعزيز الشعور بالانتماء.\n
•	رفع الوعي الذاتي لدى المعلمين.\n
•	التعامل مع العواطف وزراعة أدوات إدارة العواطف في نظام التعليم.\n
•	تعزيز الوعي الاجتماعي والسياسي لدى المعلمين من خلال التعرض لمختلف القضايا الاجتماعية.\n
•	استيعاب ودمج التعلم الاجتماعي العاطفي في الخطط الدراسية في مجالات المعرفة المختلفة.\n

`,
    audience: [
      `                   طلاب ابتدائي : اول-سادس`,
      `طلاب اعدادي:   سابع -تاسع`,
      `                          طلاب ثانوي:   عاشر-ثاني عشر `,
      `      أولياء امور 
`,

      `طاقم التدريس`,
    ],
    tech: false,
    Image: P20,
  },
  {
    id: "48194",
    name: `برنامج  الشطرنج والاستراتيجية   `,

    desc: `مصطلح  الإستراتيجية يتطرق الى تعلم مبادئ الإستراتيجية الأساسية كالتحكم في المركز وتطوير الأدوات وتحديد الرّؤية والرّسالة والأهداف.
- تعلم الإستراتيجية عن طريق لعبة الشطرنج  التي تحتاج الى التخطيط المسبق والتفكير المستقبلي والتنبؤ بالنتائج المحتملة , ومن هنا ننمي طلاب قادرين على حل المشكلات, تحليل المواقف المعقدة واتخاذ القرارات تحت الضغط والتعامل مع التحديات الغير متوقعة.
في هذا البرنامج، نستثمر تعليم الطُّلاب الشّطرنج لتعويدهم بناء استراتيجيّات في حياتهم
\n-------------------\n
                                                                                اهداف البرنامج
                                                                                \n-------------------\n
- الهدف الأساسي لتعلم الشطرنج: تنمية مهارات التفكير.\n
- التكتيكات: تحديد الفرص والحسابات الدقيقة والتخطيط لتحركات متطورة. حل التمارين التكتيكية والاستراتيجية لتحسين رؤية اللعبة والقدرة على حساب المتغيرات.\n
سيساعد تحليل ألعاب اللاعبين الأقوياء الطالب على تعلم استراتيجيات وتكتيكات جديدة.\n
-التركيز: تتطلب لعبة الشطرنج تركيزًا عاليًا بمرور الوقت، وتصفية عوامل التشتيت والتركيز على اللعبة.\n
- الذاكرة: يطوّر لاعبو الشطرنج ذاكرة بصرية قوية وقدرة على تذكر أنماط اللعبة والحركات المعقدة.\n
-  تحسين المهارات: ستكون اللعبة ضد صديق أو عائلة أو خصم عبر الإنترنت لاكتساب الخبرة وتحسين المهارات. \n
- الدراسة المتقدمة: يدرس الطالب مواد أكثر تقدما مثل الانفتاح ونظرية النهايات وغيرها. \n
`,
    audience: [
      `  طلاب ابتدائي : ثالث-سادس`,
      `                          طلاب اعدادي:   سابع -تاسع`,
      `                          طلاب ثانوي:   عاشر `,
      `طلاب  صف بستان`,
    ],
    tech: false,
    Image: P21,
  },
  {
    id: "48184",
    name: `برنامج 
نمط حياة صحي لنا جميعا
 `,

    desc: `التغذية السليمة والنشاط البدني ضروريان للنمو الطبيعي والصحي عند الأطفال. فعملية اكتساب عادات التغذية والنشاط البدني في سن مبكرة وترافق الشخص طوال الحياة.
تعاني نسبة عالية من الأطفال من زيادة الوزن أو السمنة نتيجة سوء التغذية وقلة النشاط. يمكن للمدارس تعزيز الصحة من خلال التثقيف حول التغذية السليمة، والحد من الأغذية غير الصحية، وتشجيع النشاط البدني. أظهرت الدراسات أن اتباع نظام غذائي صحي وممارسة النشاط البدني لهما تأثير إيجابي على التحصيل الدراسي والتركيز.
\n-------------------\n
اهداف البرنامج
\n-------------------\n
1.	تساعدنا التغذية السليمة في الحفاظ على وزن صحي، مما يساهم بشكل كبير في صحتنا ويقلل من خطر الإصابة بالعديد من الأمراض مثل مشاكل القلب أو الأوعية الدموية وغيرها.
2.	النظام الغذائي الصحيح والصحي يوازن ويساعد على الوقاية من الأمراض. 
تقوية جهاز المناعة. واتباع نظام غذائي غني بالفيتامينات والمعادن يقوي جهاز المناعة ويساعد الجسم على مواجهة العدوى والأمراض.
3.	تحسين مستويات الطاقة: فالتغذية السليمة تزود الجسم بالطاقة التي يحتاجها للقيام بوظائفه بشكل سليم وتقلل من الشعور بالتعب والضعف.
`,
    audience: [
      `  طلاب ابتدائي : ثالث-سادس`,
      `                          طلاب اعدادي:   سابع -تاسع`,
      `                          طلاب ثانوي:   عاشر `,
      `طلاب  صف بستان`,
    ],
    tech: false,
    Image: P22,
  },
  {
    id: "23647",
    name: `برنامج 
  مبادرون     بالتكنولوجيا الفائقة(هاي تيك -hi-tech)
`,
    desc: `نتيجة التحديات المختلفة التي يمر بها المجتمع العربي وخاصة العنف نطمح من خلال هذا البرنامج غرس الفكر الريادي والإبداعي لدى الطلاب . 
تقدم الدورة توضيحا للقيم والرؤية من أجل تحويلها إلى عمل حقيقي من خلال اللقاءات التي توفر تدريجيا أدوات لتأسيس الجيل الجديد. من خلال تعلم طريقة البرمجة.  
ولأن أغلب الطلاب يُحبّون التّكنولوجيا، فإنّنا في هذا البرنامج سنستثمر هذا الأمر كي نُطوّر لديهم الروح الرياديّة والمُبادرة، وهذا الأمر الأهم الذي يحتاج إليه مُجتمعُنا. 
\n-------------------\n

اهداف البرنامج
\n-------------------\n

1.	 تشجيع الشباب على تحمل المسؤولية واقتراح الحلول لمختلف المشاكل الاجتماعية في المجتمع العربي.

2.	 تطوير قنوات التفكير النقدي بين الشباب وتعريضهم لعالم الأعمال من خلال نقل المعرفة والأدوات اللازمة لتخطيط وتنفيذ المبادرات الشخصية.
`,
    audience: [
      `                          طلاب ثانوي:  عاشر-حادي عشر`,
      `                          طلاب اعدادي:   ثامن-تاسع`,
    ],
    tech: true,
    Image: P23,
  },
 /*  {
    id: "48194",
    name: `برنامج  الشطرنج والاستراتيجية   `,

    desc: `مصطلح  الإستراتيجية يتطرق الى تعلم مبادئ الإستراتيجية الأساسية كالتحكم في المركز وتطوير الأدوات وتحديد الرّؤية والرّسالة والأهداف.
- تعلم الإستراتيجية عن طريق لعبة الشطرنج  التي تحتاج الى التخطيط المسبق والتفكير المستقبلي والتنبؤ بالنتائج المحتملة , ومن هنا ننمي طلاب قادرين على حل المشكلات, تحليل المواقف المعقدة واتخاذ القرارات تحت الضغط والتعامل مع التحديات الغير متوقعة.
في هذا البرنامج، نستثمر تعليم الطُّلاب الشّطرنج لتعويدهم بناء استراتيجيّات في حياتهم
\n-------------------\n
                                                                                اهداف البرنامج
                                                                                \n-------------------\n
- الهدف الأساسي لتعلم الشطرنج: تنمية مهارات التفكير.\n
- التكتيكات: تحديد الفرص والحسابات الدقيقة والتخطيط لتحركات متطورة. حل التمارين التكتيكية والاستراتيجية لتحسين رؤية اللعبة والقدرة على حساب المتغيرات.\n
سيساعد تحليل ألعاب اللاعبين الأقوياء الطالب على تعلم استراتيجيات وتكتيكات جديدة.\n
-التركيز: تتطلب لعبة الشطرنج تركيزًا عاليًا بمرور الوقت، وتصفية عوامل التشتيت والتركيز على اللعبة.\n
- الذاكرة: يطوّر لاعبو الشطرنج ذاكرة بصرية قوية وقدرة على تذكر أنماط اللعبة والحركات المعقدة.\n
-  تحسين المهارات: ستكون اللعبة ضد صديق أو عائلة أو خصم عبر الإنترنت لاكتساب الخبرة وتحسين المهارات. \n
- الدراسة المتقدمة: يدرس الطالب مواد أكثر تقدما مثل الانفتاح ونظرية النهايات وغيرها. \n
`,
    audience: [
      `  طلاب ابتدائي : ثالث-سادس`,
      `                          طلاب اعدادي:   سابع -تاسع`,
      `                          طلاب ثانوي:   عاشر `,
      `طلاب  صف بستان`,
    ],
    tech: false,
    Image: P14,
  }, */

  {
    id: "41537",
    name: `برنامج  المناظرة والخطابة   `,

    desc: `هنا نسلط الضوء على المبادئ التوجيهية للطلاب لمحو الأمية اللغوية، العربية والعبرية والإنجليزية عن طريق فن التقديم والعرض وجودة الوقوف وفق مبادئها النوعية والإرشادية. إنّ أهمّ المَلكات الذتي يَحتاجُ إلبه النّاسُ عامّة، والطُّلابُ خاصّةً. لأنّ الطّبيب يحتاجُ ان يَتواصل مع مرضاه، وكذلك المُعلم والتّاجر وكل شخص. وقد اثبتت الدّراسات أن أكثر شيءٍ يَخافُ منه الإنسانُ هو الوقوفُ أمام الجمهور، فإذا استطعنا أن نُعوّد الطالب ونأهله من الآن للوقوف أمام الجمهور فإنّنا نُربيه على الكاريزما والقيادة.  
                  \n-------------------\n
        
   اهداف البرنامج
   \n-------------------\n

-  تدريب أشخاص ذوو ذكاء لغوي عال وثقة بالنّفسِ..
- تطوير الكاريزما والقُدرة على التأثير
- تعزيز الوعي الاجتماعي والمشورة الحكيمة عن طريق التفكير بطريقة "الاهتمام".
- سيوفر البرنامج للطلاب أساليب الصياغة والإقناع والتفاوض لغرض قيادة مشروع وفكرة رائعة من حياة الإنسان كعامل اجتماعي للتغيير الاجتماعي، من ناحية أخرى سيعزز قوة الوقوف أمام الجمهور.
- سيوفر البرنامج للطلاب أساليب الصياغة والإقناع والتفاوض لغرض قيادة مشروع وفكرة رائعة من حياة الإنسان كعامل اجتماعي للتغيير الاجتماعي، من ناحية أخرى سيعزز قوة الوقوف أمام الجمهور.
- تمكين الطلاب من مبادئ الخطابة  بمنظور ثلاثي اللغات.                                                   
`,
    audience: [
      `                          طلاب ثانوي :  عاشر`,
      `                          طلاب اعدادي:   سابع -تاسع`,
      `طلاب ابتدائي :   رابع-سادس`,
    ],
    tech: false,
    Image: P24,
  },
];
