import React from "react";

export default function BlodSectionNewsletter9() {
  const toast = (str, extraClass = "", timeout = 3000) => {
    const div = document.createElement("div");
    div.className = "toast " + extraClass;
    div.innerHTML = str;
    setTimeout(function () {
      div.remove();
    }, timeout);
    document.body.appendChild(div);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append("access_key", "62e53c52-8437-41ac-8129-85788bc22585");
    formData.append("news_letter", "اضف للنشرة الاخبارية");
    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: json,
    }).then((res) => res.json());

    if (res.success) {
      console.log("Success", res);
      toast(" تم ارسال الرسالة بنجاح", "success", 5000);
    } else {
      console.log("error", res);
      toast(" تعذر ارسال الرسالة ", "error", 5000);
    }
  };
  return (
    <React.Fragment>
      <>
        <section className="relative pt-32 pb-24 bg-white overflow-hidden">
          <img
            className="absolute bottom-0 left-1/2 transform -translate-x-1/2"
            src="flaro-assets/images/newsletter/gradient.svg"
            alt
          />
          <div className="relative z-10 container px-4 mx-auto">
            <div className="text-center max-w-2xl mx-auto">
              <p className="mb-5 text-5xl text-indigo-600 font-semibold uppercase tracking-px">
                النشرة الإخبارية
              </p>
              <h2 className="mb-11 text-6xl md:text-7xl text-center font-bold font-heading tracking-px-n leading-tight">
                انضم للحصول على محتويات حصرية مجانًا.
              </h2>
              <form className="mb-11 md:max-w-md mx-auto" onSubmit={onSubmit}>
                <div className="mb-5">
                  <input
                    className="px-4 py-4 w-full text-gray-500 font-medium text-center  placeholder-gray-500 outline-none border border-gray-300 rounded-lg focus:ring focus:ring-indigo-300"
                    id="newsletterInput4-1"
                    type="text"
                    name="email"
                    placeholder="عنوان البريد الالكتروني"
                  />
                </div>
                <button
                  className="py-4 px-6 w-full text-white font-semibold rounded-xl shadow-4xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200"
                  type="submit"
                >
                  انضم الان
                </button>
              </form>
              <ul className="-m-4">
                <li className="inline-flex flex-wrap items-center p-4">
                  <svg
                    style={{ marginLeft: "0.625rem" /* 10px */ }}
                    width={18}
                    height={18}
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.75 9.75L6.75 12.75L14.25 5.25"
                      stroke="#186972"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="font-medium leading-relaxed">
                    احصل على التقارير الاسبوعية{" "}
                  </span>
                </li>
                <li className="inline-flex flex-wrap items-center p-4">
                  <svg
                    style={{ marginLeft: "0.625rem" }}
                    width={18}
                    height={18}
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.75 9.75L6.75 12.75L14.25 5.25"
                      stroke="#186972"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="font-medium leading-relaxed">
                    موارد مجانية غير محدودة{" "}
                  </span>
                </li>
                <li className="inline-flex flex-wrap items-center p-4">
                  <svg
                    style={{ marginLeft: "0.625rem" }}
                    width={18}
                    height={18}
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.75 9.75L6.75 12.75L14.25 5.25"
                      stroke="#186972"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="font-medium leading-relaxed">
                    المحتويات المميزة{" "}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
