import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import FeaturesSectionFeatures1 from "../components/features/FeaturesSectionFeatures1";
import FeaturesSectionFeatures3 from "../components/features/FeaturesSectionFeatures3";
import FeaturesSectionFeatures2 from "../components/features/FeaturesSectionFeatures2";
import FeaturesSectionFeatures4 from "../components/features/FeaturesSectionFeatures4";
import FeaturesSectionFeatures5 from "../components/features/FeaturesSectionFeatures5";
import FeaturesSectionFeatures6 from "../components/features/FeaturesSectionFeatures6";
import FeaturesSectionFeatures7 from "../components/features/FeaturesSectionFeatures7";
import FeaturesSectionFeatures8 from "../components/features/FeaturesSectionFeatures8";
import FeaturesSectionFeatures9 from "../components/features/FeaturesSectionFeatures9";
import FeaturesSectionFeatures11 from "../components/features/FeaturesSectionFeatures11";
import FeaturesSectionFeatures13 from "../components/features/FeaturesSectionFeatures13";
import FeaturesSectionFeatures12 from "../components/features/FeaturesSectionFeatures12";
import FeaturesSectionFeatures10 from "../components/features/FeaturesSectionFeatures10";
import Nav from "../components/Nav/Nav";
import IndexSectionFooters7 from "../components/footers/IndexSectionFooters7";

const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function About() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <Nav />
      <FeaturesSectionFeatures10 />
      <FeaturesSectionFeatures9 />
     {/*  <FeaturesSectionFeatures3 />

      <FeaturesSectionFeatures4 />

      <FeaturesSectionFeatures6 />
 */}
      <FeaturesSectionFeatures11 />
      <FeaturesSectionFeatures13 />
      {/* <FeaturesSectionFeatures12 />
       */}


<IndexSectionFooters7 />
    </React.Fragment>
  );
}
