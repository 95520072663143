import React from "react";
import Google from "../../assets/images/p1.jpg";
import Google1 from "../../assets/images/p2.jpg";
import Google2 from "../../assets/images/p3.jpg";
import Google3 from "../../assets/images/p4.jpg";
import Google4 from "../../assets/images/p5.jpg";
import Google5 from "../../assets/images/p6.jpg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";

export default function LogosSectionLogos1() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },

    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <React.Fragment>
      <>
        <section className="relative pt-28 pb-36 bg-blueGray-100 overflow-hidden">
          <img
            className="absolute top-0 left-0"
            src="flaro-assets/images/logos/gradient3.svg"
            alt
          />
          <div className="relative z-10 mb-14  text-center">
            <h2 className="mb-14 text-6xl text-gray-600 md:text-7xl text-center   font-semibold  font-heading tracking-px-n leading-tight">
              الشركاء
            </h2>

            {/*  <div className="flex flex-wrap max-w-5xl mx-auto -m-3"> */}
            <Carousel
              swipeable={false}
              draggable={false}
              showDots={false}
              responsive={responsive}
              ssr={false} // means to render carousel on server-side.
              infinite={true}
              autoPlay={
                true
              } /* this.props.deviceType !== "mobile" ? true : false */
              autoPlaySpeed={500}
              keyBoardControl={false}
              /* customTransition="all .5" */
              customTransition="transform 1000ms ease-in-out"
              transitionDuration={2000}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
              /* deviceType={this.props.deviceType} */
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
            >
              <div>
                {" "}
                <div className="p-4">
                  <div
                    className="flex items-center justify-center py-4 px-4 h-full bg-white rounded-3xl"
                    style={{ height: 160 }}
                  >
                    <img
                      src={Google}
                      className=" p-5"
                      style={{ maxHeight: "100%" }}
                    ></img>
                  </div>
                </div>
              </div>
              <div>
                <div className="p-4">
                  <div
                    className="flex items-center justify-center py-4 px-4 h-full bg-white rounded-3xl"
                    style={{ height: 160 }}
                  >
                    {/* <img src="flaro-assets/logos/brands/logo-xxl2.png" alt /> */}
                    <img
                      src={Google1}
                      className="p-5"
                      style={{ maxHeight: "100%" }}
                    ></img>
                  </div>
                </div>{" "}
              </div>
              <div>
                <div className="p-4">
                  <div
                    className="flex items-center justify-center py-4 px-4 h-full bg-white rounded-3xl"
                    style={{ height: 160 }}
                  >
                    <img
                      src={Google2}
                      className="p-5"
                      style={{ maxHeight: "100%" }}
                    ></img>
                  </div>
                </div>
              </div>
              <div>
                <div className="p-4">
                  <div
                    className="flex items-center justify-center py-4 px-4 h-full bg-white rounded-3xl"
                    style={{ height: 160 }}
                  >
                    <img
                      src={Google3}
                      className="p-5"
                      style={{ maxHeight: "100%" }}
                    ></img>
                  </div>
                </div>{" "}
              </div>
              <div>
                <div className="p-4">
                  <div
                    className="flex items-center justify-center py-4 px-4 h-full bg-white rounded-3xl"
                    style={{ height: 160 }}
                  >
                    <img
                      src={Google4}
                      className="p-5"
                      style={{ maxHeight: "100%" }}
                    ></img>
                  </div>
                </div>
              </div>
              <div>
                {" "}
                <div className="p-4">
                  <div
                    className="flex items-center justify-center py-4 px-4 h-full bg-white rounded-3xl"
                    style={{ height: 160 }}
                  >
                    <img
                      src={Google5}
                      className="p-5"
                      style={{ maxHeight: "100%" }}
                    ></img>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>

          <div className="flex justify-center">
            <Link to="/contact">
              <button
                className="py-4 px-6  w-full text-white font-semibold border border-indigo-700 rounded-xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200"
                type="button"
                style={{ maxWidth: 450 }}
              >
                {/*               تواصلوا الان وانضمو الى شبكتنا
                 */}
                تواصلوا معنا الآن وكونوا جزءاً من شبكتنا
              </button>
            </Link>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
