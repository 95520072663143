import React from "react";
import { Programs } from "../../data/Programs";
import "./blog.css";
import { Link } from "react-router-dom";
export default function ProgramsSectionBlog3() {
  return (
    <React.Fragment>
      <>
        <section
          className="py-32 bg-white overflow-hidden"
          style={{ paddingTop: 200 }}
        >
          <div className="container px-4 mx-auto">
            <div className="flex flex-wrap -m-8">
              <div className="w-full md:w-5/12 p-8">
                <div className="flex flex-col justify-between h-full">
                  <div className="mb-8">
                    <h2 className="mb-5 text-6xl md:text-7xl font-bold font-heading tracking-px-n leading-tight">
                      البرامج التي نقدمها
                    </h2>
                    <p className="text-gray-600 font-medium leading-relaxed">
                      وفر لطلابك أفضل برامج الإرشاد الجماعي لتعزيز مهاراتهم
                      الشخصية والمهنية، وتمكينهم من تحقيق التميز والنجاح في
                      المستقبل. انضمام مدرستكم إلى هذه الدورات سيمنح طلابكم فرصة
                      فريدة للتطوير الشامل، مما يزيد من جاهزيتهم لسوق العمل
                      ويعزز مكانة مدرستكم كوجهة تعليمية متميزة.
                    </p>
                  </div>
                  {/*  <a
                    className="inline-flex items-center text-indigo-600 hover:text-indigo-700 leading-normal"
                    href="#"
                  >
                    <span
                      className="ml-2 font-semibold"
                      style={{ marginLeft: "0.5rem" }}
                    >
                      الى جميع البرامج
                    </span>
                    <svg
                      width={18}
                      height={18}
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ transform: " rotateY(180deg)" }}
                    >
                      <path
                        d="M10.5 3.75L15.75 9M15.75 9L10.5 14.25M15.75 9L2.25 9"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </a> */}
                </div>
              </div>

              <div className="w-full md:flex-1 p-8">
                <div className="flex flex-wrap -m-3  ">
                  <div className="w-full md:w-1/2 p-3 	">
                    <div className="max-w-sm mx-auto">
                      <div className="mb-6  overflow-hidden rounded-xl">
                        <Link
                          to={`/program/${Programs[0].id}`}
                          relative="path"
                          params={{ id: Programs[0].id }}
                        >
                          <img
                            className="transform hover:scale-105 transition ease-in-out duration-1000 w-full h-full   border       "
                            /*   src="flaro-assets/images/blog/blog-wide.png"
                          alt */
                            style={{
                              height: 230,
                              objectFit: "cover",
                              objectPosition: "center",
                              cursor: "pointer",
                            }}
                            src={Programs[0].Image}
                          />
                        </Link>
                      </div>
                      <div className="mb-4">
                        <a className="mb-4 font-sans px-3 py-1.5 text-sm text-indigo-600 font-semibold bg-indigo-50 uppercase rounded-md ">
                          {Programs[0].tech ? "تكنولوجي" : " تربوي واجتماعي"}
                        </a>
                      </div>
                      <Link
                        to={`/program/${Programs[0].id}`}
                        relative="path"
                        params={{ id: Programs[0].id }}
                        className="mb-2 inline-block hover:text-gray-800 hover:underline"
                      >
                        <h3 className="text-xl font-bold font-heading leading-normal">
                          {Programs[0].name}{" "}
                        </h3>
                      </Link>

                      <p className="text-gray-600 font-medium leading-relaxed flex flex-wrap">
                        {/*  <h4 className="p-1 mb-4 text-l font-bold font-heading leading-normal">
                          קהל יעד
                        </h4> */}
                        {Programs[0].audience.map((element) => {
                          return (
                            <p className="p-1 mb-4 text-sm uppercase ">
                              <a className="mb-4 font-sans px-3 py-1.5 text-sm text-indigo-600  bg-indigo-white uppercase rounded-md shadow  ">
                                {element}
                              </a>
                            </p>
                          );
                        })}
                      </p>
                    </div>
                  </div>

                  <div className="w-full md:w-1/2 p-3  	 ">
                    <div className="max-w-sm mx-auto">
                      <div className="mb-6  overflow-hidden rounded-xl">
                        <Link
                          to={`/program/${Programs[1].id}`}
                          relative="path"
                          params={{ id: Programs[1].id }}
                        >
                          <img
                            className="transform hover:scale-105 transition ease-in-out duration-1000 w-full h-full object-cover object-contain object-center  border "
                            style={{
                              height: 230,
                              objectFit: "cover",
                              objectPosition: "center",
                              cursor: "pointer",
                            }}
                            src={Programs[1].Image}
                          />
                        </Link>
                      </div>
                      <div className="mb-4">
                        <a className="mb-4 font-sans px-3 py-1.5 text-sm text-indigo-600 font-semibold bg-indigo-50 uppercase rounded-md ">
                          {Programs[1].tech ? "تكنولوجي" : " تربوي واجتماعي"}
                        </a>
                      </div>

                      <Link
                        to={`/program/${Programs[1].id}`}
                        relative="path"
                        params={{ id: Programs[1].id }}
                        className="mb-2 inline-block hover:text-gray-800 hover:underline"
                      >
                        <h3 className="text-xl font-bold font-heading leading-normal">
                          {Programs[1].name}{" "}
                        </h3>
                      </Link>
                      <p className="text-gray-600 font-medium leading-relaxed flex flex-wrap">
                        {/* <h4 className="text-l font-bold font-heading leading-normal">
                          קהל יעד
                        </h4>
                        {Programs[1].audience.map((element) => {
                          return <li>{element}</li>;
                        })} */}
                        {Programs[1].audience.map((element) => {
                          return (
                            <p className="p-1 mb-4 text-sm uppercase ">
                              <a className="mb-4 font-sans px-3 py-1.5 text-sm text-indigo-600  bg-indigo-white uppercase rounded-md shadow  ">
                                {element}
                              </a>
                            </p>
                          );
                        })}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container px-4 mx-auto py-32">
            <div className="flex flex-wrap -m-8 ">
              <div className="w-full md:flex-1 p-8">
                <div className="flex flex-wrap -m-3 justify-between">
                  {Programs.slice(2).map((element) => {
                    return (
                      <div
                        className="w-full md:w-1/2 p-3 lg:w-1/3  mb-4  rounded-xl feed  border "
                        style={{
                          "@media (min-width: 768px)": {
                            maxWidth: "48%",
                          },
                          "@media (min-width: 1024px)": {
                            maxWidth: "32%",
                          },
                        }}
                      >
                        <div className="max-w-sm mx-auto">
                          <div
                            className="mb-6  overflow-hidden rounded-xl"
                            style={{ marginTop: "1rem" }}
                          >
                            <Link
                              to={`/program/${element.id}`}
                              relative="path"
                              params={{ id: element.id }}
                            >
                              <img
                                className="transform hover:scale-105 transition ease-in-out duration-1000 w-full h-full   border cursor-pointer      "
                                alt
                                style={{
                                  height: 230,
                                  objectFit: "cover",
                                  objectPosition: "center",
                                  cursor: "pointer",
                                }}
                                src={element.Image}
                              />
                            </Link>
                          </div>
                          <div className="mb-4">
                            <a className="mb-4 font-sans px-3 py-1.5 text-sm text-indigo-600 font-semibold bg-indigo-50 uppercase rounded-md ">
                              {element.tech ? "تكنولوجي" : " تربوي واجتماعي"}
                            </a>
                          </div>

                          <Link
                            to={`/program/${element.id}`}
                            relative="path"
                            params={{ id: element.id }}
                            className="mb-2 inline-block hover:text-gray-800 hover:underline"
                          >
                            <h3 className="text-xl font-bold font-heading leading-normal">
                              {element.name}{" "}
                            </h3>
                          </Link>
                          <p className="text-gray-600 font-medium leading-relaxed flex flex-wrap">
                            {element.audience.map((element) => {
                              return (
                                <p className="p-1 mb-4 text-sm uppercase ">
                                  <a className="mb-4 font-sans px-3 py-1.5 text-sm text-indigo-600  bg-indigo-white uppercase rounded-md shadow  ">
                                    {element}
                                  </a>
                                </p>
                              );
                            })}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
